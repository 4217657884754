body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.custom-zoom [data-rmiz-modal-overlay='hidden'] {
    background-color: rgb(56, 58, 89, 0);
}
.custom-zoom [data-rmiz-modal-overlay='visible'] {
    background-color: rgb(20 20 25 / 85%);
}
